<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="enterpise-apps-page" v-if="!loading">
    <b-card>
      <!-- form -->
      <b-form>
        <b-row>
          <!-- bio -->
          <b-col cols="6">
            <b-form-group
              label="Organisation Name"
              label-for="display-name"
            >
              <b-form-input
                id="display-name"
                v-model="organisation.display_name"
                rows="4"
                placeholder="Organisation Name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Contact Number"
              label-for="contact-number"
            >
              <b-form-input
                id="contact-number"
                v-model="organisation.contact_number"
                rows="4"
                placeholder="Your Contact number"
              />
            </b-form-group>
          </b-col>
          <!--/ bio -->
          <b-col cols="6">
            <b-form-group
              label="Address Line 1"
              label-for="address1"
            >
              <b-form-input
                id="address1"
                v-model="organisation.address_line_1"
                rows="4"
                placeholder="First line of address"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Address Line 2"
              label-for="address2"
            >
              <b-form-input
                id="address2"
                v-model="organisation.address_line_2"
                rows="4"
                placeholder="Second line of address"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Town/City"
              label-for="town-city"
            >
              <b-form-input
                id="town-city"
                v-model="organisation.town_city"
                rows="4"
                placeholder="Town/City"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="County"
              label-for="county"
            >
              <b-form-input
                id="county"
                v-model="organisation.county"
                rows="4"
                placeholder="County"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Postcode"
              label-for="postcode"
            >
              <b-form-input
                id="postcode"
                v-model="organisation.postcode"
                rows="4"
                placeholder="Postcode"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-checkbox
              :checked="organisation.auto_close_issues"
              v-model="organisation.auto_close_issues"
              name="auto_close_issues"
              switch
              inline
            > Autoclose issues
            </b-form-checkbox>
          </b-col>
          <!--/ Country -->

          <b-col cols="12">
            <b-button
              class="mt-1 mr-1"
              :class="{'disabled': !checkFormValid()}"
              variant="primary" @click="updateOrganisation()"
            >
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>

  import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
  } from 'bootstrap-vue'

  import OrganisationService from '../../../services/OrganisationService'

  export default {
    name: 'Organisation',
    components: {
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
    },
    data() {
      return {
        organisation: {
          name: '',
          address_line_1: '',
          address_line_2: '',
          town_city: '',
          county: '',
          post_code: '',
          contact_number: '',
          auto_close_issues: '',
        },
        loading: true,
        selected: [],
      };
    },
    mounted() {
      this.getOrganisation();
    },
    methods: {
      getOrganisation() {
        this.loading = true;
        OrganisationService.getOrganisation().then(res => {
          this.organisation = res.data;
        }).catch(() => {
          this.$toast.error('Could not get enterprise, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        }).finally(() => {
          this.loading = false;
        });
      },
      updateOrganisation() {
        const org = {
          auto_close_issues: this.organisation.auto_close_issues,
          org_address_line_1: this.organisation.address_line_1,
          org_address_line_2: this.organisation.address_line_2,
          org_contact_number: this.organisation.contact_number,
          org_county: this.organisation.county,
          org_postcode: this.organisation.postcode,
          org_town_city: this.organisation.town_city,
          organisation_name: this.organisation.display_name,
        };
        OrganisationService.updateOrganisation(org).then(() => {
          this.$toast.success(`Updated organisation ${this.organisation.display_name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(() => {
          this.$toast.error('Could not update group, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        const name = this.organisation.display_name.length > 0;

        return name;
      },
    },
  };
</script>
